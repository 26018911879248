import React from 'react';

function PrivacyEnglish() {
  return (
    <div>
      <h2>Privacy Policy</h2>
      <h4>Processing of Personal Data</h4>
      <p>For the needs of protection of Private Policy, the following clarifications are stated:</p>
      <p>
        The company mentioned here " VIVIAN LAB " is defined as "the Process Manager - Processor"
        and therefore, among other things, collects, stores, uses, processes and routes personal
        data that falls within the scope of its updating and execution of tasks when the Data
        Subject visits, registers, uses in any way the Company's websites - Websites - as well as
        the Company's mobile / technological applications - Applications ( Apps ). As the protection
        of personal data is extremely important for our company, this document defines the
        obligations, the way of using, communicating and protecting the data we receive as well as
        how we can resolve any kind of issue through communication between us.
      </p>
      <br />
      <br />
      1. Definitions
      <br />
      1.1 Data Protection Legislation means any law relating to the processing of personal data,
      privacy and security, including, without limitation, Regulation (EU) 2016/679 “for the
      protection of natural persons with regard to the processing of personal data and for the free
      circulation of such data and the repeal of Directive 95/46/EC (General Data Protection
      Regulation)" ("GDPR"), Directive 2002/58/EC "on the protection of privacy in electronic
      communications", as incorporated, valid and applied in the Greek legal order and as amended
      from time to time, or other applicable or replacing the above international or national laws
      or rules concerning the protection of personal data, as well as relevant regulations,
      instructions or guidelines of competent administrative authorities , such as the Greek
      Personal Data Protection Authority.
      <br />
      1.2 "Administrator", "processor", "data subject", "personal data" and "processing" shall have
      the meaning assigned to them in the applicable Data Protection Legislation.
      <br />
      1.3 Personal Data means all personal data, as defined in the applicable Data Protection
      Legislation, which are disclosed or may be disclosed to the Processor or made available to him
      for the purposes and in the context of each communication, and/or which the Processor (and/or
      its respective subcontractor) collects, stores or in any other way processes on behalf of the
      Company, as the processor in the context of each communication and cooperation.
      <br />
      1.4 Services: Those mentioned on the official site of our Company mentioned and offered for
      use.
      <br />
      1.5. Websites: About ' www.vivianlab.com' which is the website of our Company, which maintains
      an electronic platform for placing orders from collaborating stores of health interest,
      providing its customers with immediate service and transfer of the above orders through the
      Company's electronic platform from a network of independent distributors and collaborating
      stores .
      <br />
      1.6. Processing of Personal Data is any act or series of acts carried out with or without the
      use of automated means, on personal data or sets of personal data, such as the collection,
      registration, organization, structuring, storage, adaptation or alteration, retrieval, search
      of information , use, disclosure by transmission, dissemination or any other form of disposal,
      association or combination, limitation, deletion or destruction.
      <br />
      <br />
      2. Purpose of Processing and Duration
      <br />
      2.1 The Processor prior to handing over data to the Processor that falls within the scope of
      the relevant Data Protection legislation and for which the Processor is primarily responsible
      and/or accountable under the relevant Data Protection legislation (the " Personal Data
      Controller") under or in connection with the Terms and Conditions hereof or any Agreement must
      have obtained from the Data subject the consent required for the transfer. The same applies to
      the case where the Processor collects and generally processes personal data that it receives
      directly from the subject on behalf of the Responsible Processor.
      <br />
      2.2 The Company will process the Personal Data for the purpose of providing the Services
      offered. Subsequently, it will use the Personal Data only when it is absolutely necessary to
      fulfill its obligations, in accordance with the Terms and Conditions imposed by the Law.
      <br />
      2.3 The processing actions will last for the period of time required to complete the Services.
      <br />
      2.4 The Personal Data will always be protected by the Company as Processor with the character
      of Confidential Protected Information.
      <br />
      <br />
      3. Is it mandatory to provide your Personal Data?
      <br />
      Providing the Data to the Company may be necessary to achieve the purposes specified in this
      Privacy Policy or may be optional.
      <br />
      The mandatory or optional nature of the provision of Data is indicated by an asterisk (*) next
      to mandatory personal data. If you refuse to provide the information marked as mandatory on
      the Websites, it will be impossible to achieve the main purpose for which the specific Data is
      collected, and it may, for example, make it impossible for the Company to fulfill the
      transportation service or provide of the other services available on its Websites. The
      provision of additional Data to the Company, beyond those marked as mandatory, is optional and
      has no consequences regarding the main purposes of Data collection, since its provision serves
      exclusively to optimize the quality of the services provided by us.
      <br />
      <br />
      4. What Personal Data do we collect about you? We take care to collect only your absolutely
      necessary Personal Data, which is appropriate and clear for the intended purpose. This Data
      includes the following:
      <br />
      a. Data you provide us during your registration and the creation of a user account on the
      Company's Websites or Apps, via the internet or your mobile phone or through your personal
      contact with our company and specific data such as an electronic address (e- mail ) * and
      password/ login password (as mandatory) and first name, last name, postal address, phone
      number (as optional):
      <br />
      b. Data and information that you provide to us through our transactions (services, options,
      synergies, etc.) and communication between us (the website, partners, phone, e-mail or through
      any other means). For example, we collect notes from our conversations with you, details about
      any complaints or comments you make, details about the services you choose, recurring
      preferences, wish list of options and services list ), coupon redemptions, which of our
      websites you visit and how and when you contact us.
      <br />
      c. Data related to the payment method for the transactions you carry out with us.
      <br />
      d. Data you provide us when you subscribe to our newsletter or any kind of informative
      material.
      <br />
      e. Data about the options and services that you usually choose in preference. In order to
      recommend services of interest to you and to further improve your experience of working with
      us and in general your transactions through our company and the use of our website . Of
      course, you always have the option not to share such information with us.
      <br />
      f . Our website usage data.
      <br />
      g. Information collected from the use of cookies in your browser. Learn more about how to use
      cookies here ( Link ).
      <br />
      h. In order to provide the best possible website experience, we collect technical information
      about your internet connections and browser, as well as the country and telephone code where
      your computer is located, the web pages displayed during your visit, advertisements which you
      click on and any search terms you entered.
      <br />
      i. Your social media username, if you interact with us through these channels, to help us
      respond to your comments, questions or feedback.
      <br />
      j. Educational information, such as studies, skills, knowledge of foreign languages, work
      experience (only in cases where you are responding to a job advertisement)
      <br />
      <br />
      <p>
        <h4>Google Meet and Google Calendar Integration</h4>
        We highly value your privacy and are fully committed to safeguarding your personal
        information. Our platform integrates with Google Meet and Google Calendar to provide a
        seamless, secure, and user-friendly experience for scheduling and managing virtual meetings.
        This integration is designed to streamline your workflow, allowing you to arrange meetings
        directly within our platform without needing external tools.
        <br />
        <strong>How the Integration Works</strong>
        <br />
        Our robust integration with the Google Calendar API allows Google Account users on our
        platform to effortlessly create, update, and delete meetings, with meeting links
        automatically managed through their Google Calendar. By utilizing the Google Calendar API’s
        <a href="https://www.googleapis.com/auth/calendar.events.owned">
          https://www.googleapis.com/auth/calendar.events.owned
        </a>{' '}
        scope, we ensure a smooth scheduling process while maintaining strict data security
        standards.
        <br />
        <strong>Your Privacy Matters</strong>
        <br />
        We take the protection of your data very seriously. Importantly, we do not share or transfer
        your Google user data to any third parties, except as necessary to enhance and deliver this
        scheduling functionality. Our use of the Google Calendar API is strictly limited to
        improving your experience with meeting scheduling and management.
        <br />
        <strong>Data Security and Control</strong>
        <br />
        You retain complete control over your meetings, and our integration helps boost productivity
        and efficiency without compromising your privacy. We only access the necessary information
        from your Google Calendar to provide the intended functionality, ensuring that all
        interactions remain secure.
        <br />
        <strong>Third-Party Data Sharing</strong>
        <br />
        We do not share your data with any third parties, other than to facilitate the functionality
        of Google Meet and Google Calendar as described. For more details on how Google ensures
        secure sharing of user data, please refer to the
        <a href="https://support.google.com/">Google Support Article</a>.
        <br />
        <strong>Commitment to Secure Communication</strong>
        <br />
        Our Google Meet integration is part of our ongoing commitment to providing you with a
        secure, efficient, and user-centric experience. By centralizing your meeting management on
        our platform, we help simplify your communication processes while maintaining strict data
        protection practices. If you have any questions or concerns regarding your privacy and our
        integration with Google services, please feel free to contact us.
      </p>
      <p>
        <strong>5. How do we use your Personal Data?</strong>
        <br />
        Where applicable, we use your Data:
        <br />● To complete service options: The Company processes your Data in order to fulfill its
        contractual relationship, process service selection, provide customer service, comply with
        legal obligations, payment requirements, raise or exercise legal claims. If we do not
        collect your Data when completing your selection (via our service phone or via our online
        store), we will not be able to process your selection and comply with our legal obligations.
        We point out that it may be necessary to transfer your Data to third parties for the service
        and processing of the service you have preferred (For information on how we provide personal
        data to third parties, see the relevant terms below).
      </p>
      <br />
      In addition, we may retain your Data for a reasonable period of time in order to fulfill our
      contractual obligations as required by relevant law.
      <br />
      ●To Create a User Account: The Company processes your Data in order to provide you with
      account functions and to facilitate the conclusion of service provision.
      <br />
      ●For Communication: The Company uses your Data to respond to your requests/questions, refund
      requests and/or any complaints. The information you share with us enables us to manage your
      requests and respond to you in the best possible way. We may also keep a record of your
      queries/requests to us to better respond to any future communications. We do this based on our
      contractual obligations to you, our legal obligations and our legitimate interests to provide
      you with the best possible service and to be able to improve our services based on your own
      personal experience.
      <br />
      ●For sending newsletters / offers: With your consent, we will use your Personal Data,
      preferences and transaction details to inform you via e-mail , internet, phone and/or social
      media for related services including personalized / personalized offers etc. Of course you
      have the possibility to withdraw this consent at any time.
      <br />
      ●For Web push notifications : Depending on your navigation, you may receive, having previously
      given your consent, notifications about our offers, news, wish list and your main preferences
      and choices. Of course you have the possibility to withdraw this consent at any time.
      <br />
      ●For Participation in a reward program: The Company may process your Data for the needs of
      your participation in a reward program, i.e. both the examination of your application for
      participation, as well as the collection and redemption of points and in general the enjoyment
      of customer privileges, as these will be analyzed in the terms of participation in the reward
      program. This is how we are able to offer you personalized offers that interest you. Of
      course, you are free to choose whether to take advantage of them.
      <br />
      ●To develop and improve the options, offers, operation and services we provide you. We do this
      based on our legitimate business interests.
      <br />
      ●Because we want to offer you offers and suggestions that are more relevant to your interests
      and needs.
      <br />
      ●To ensure that you are always shown the most interesting content on our Sites or Apps , we
      will use the Data you have provided to us by giving us your consent to receive Apps
      notifications or - for our Sites - your consent to placing cookies on your device. For
      example, we may display a list of recently viewed services or offer you recommendations based
      on your selection history and any other Data you have shared with us.
      <br />
      ●To send you research and evaluation requests so that we can improve our services. These
      messages will not contain advertising content and do not require prior consent when sent by
      email or text message (SMS). We have a legitimate interest in doing so as it helps our
      services to be more relevant to you. Of course, you are free to opt out of receiving these
      requests from us at any time by updating your preferences in your online account.
      <br />
      ●To protect your account from fraud and other illegal activities: This includes using your
      Data to maintain, update and protect your account. We also monitor browsing activity with us
      to quickly identify and resolve any issues and protect the integrity of our website. All of
      the above are part of our legitimate interest. For example, we check your password when you
      log in and use automated IP address tracking to detect potential fraudulent logins from
      unexpected locations.
      <br />
      ●To process payments and prevent fraudulent transactions: We do this based on our legitimate
      business interests. This also helps protect our customers from fraud and cyber incidents in
      general crime .
      <br />
      ●To comply with our contractual obligations to you or in accordance with the provisions of the
      law or in execution of court orders.
      <br />
      ●To send you communications that are required by law or that are necessary to inform you of
      changes to the services we provide to you. For example, updates on these privacy notices,
      service / partnership withdrawal notices and legally required information about your choices.
      These service messages will not contain advertising content and do not require prior consent
      when sent by email or text message (SMS). If we do not use your personal data for these
      purposes, we cannot comply with our legal obligations.
      <br />
      Finally, we inform you that the processing of your Data is carried out either by the specially
      authorized personnel of the Company, or through IT systems and electronic devices by the
      Company and exceptionally by third parties, who, having contractually committed to the
      observance of confidentiality and the protection of With your data they carry out tasks
      necessary to achieve the purposes strictly related to the use of our Websites, its services
      and the sale of products through our Websites. Information about this can be found below in
      the relevant terms “Who are the recipients of your Data? How Your Data is Shared'.
      <br />
      <br />
      6. What is the legal basis for processing your Data by the Company? <br />
      ●data protection legislation which sets out various reasons why a company may collect and
      process your personal data, including the terms of our contractual relationship
      <br />
      ●your consent where required. For example when you choose to receive a newsletter . When
      collecting your personal data, we will always inform you which data is necessary in relation
      to a particular service. <br />
      ●the obligations of the Company arising from the law (e.g. tax legislation, e-commerce
      legislation, etc.) <br />
      ●the legal interest of our Company. In certain cases, we collect your Data in a way that can
      reasonably be expected as part of the operation of our business and that does not materially
      affect your rights, freedom or interests.
      <br />
      <br />
      7. Who are the recipients of your Data?
      <br />
      Access to your Data is given to the absolutely necessary personnel of the Company, who are
      bound by confidentiality, and the companies cooperating with us or third-party service
      providers , who process your Data as Processors on our behalf and in accordance with our
      orders.
      <br />
      <br />
      8. How is your Data shared?
      <br />
      Disclosure of Data by our Company:
      <br />
      The Company shares your Data with:
      <br />
      <br />
      ●Third party service providers who process personal data on behalf of the Company, for example
      (but not limited to) credit card and payment processing, transfers and deliveries, hosting,
      management and maintenance of our data, email distribution, research and analysis, management
      of promotions, as well as management of certain services and elements. When we use third party
      service providers we enter into agreements obliging them to implement appropriate technical
      and organizational measures to protect your personal data.
      <br />
      <br />
      ●Other third parties, to the extent required for the following purposes: <br />
      (i) compliance at the request of an organ of the Greek State, court order or applicable law,{' '}
      <br />
      (ii) preventing illegal uses of our Websites and Apps or violations of the Website Terms of
      Use of our Sites and Apps and our policies, <br />( iii ) our own protection against
      third-party claims, and <br />( iv ) contributing to the prevention or investigation of cases
      of fraud (e.g. counterfeiting).
      <br /> <br />
      ●other third parties to whom you yourself have given your consent.
      <br />
      <br />
      Sharing of Data by you:
      <br />
      ●When you use certain social media features on our Sites or Apps , you may create a public
      profile that includes information such as your username, profile picture, and city. You may
      also share content with your friends or the general public, including information about your
      interaction with the Company. We encourage you to use the tools we provide to manage Company
      social media sharing to control the information you make available through Company social
      media assets.
      <br />
      <br />
      9. What is the policy we apply with third party Processors of your Data in accordance with the
      above:
      <br />
      <br />
      ●We provide only the information needed to perform their specific services.
      <br />
      ●They can only use your Data for the exact purposes we set out in our contract with them.
      <br />
      ●We work closely with them to ensure that your privacy is respected and protected at all
      times.
      <br />
      ●If we stop using their services, any of the data they hold will be deleted or anonymized.
      <br />
      To improve your customer experience on our Sites and Apps , we use the following companies,
      who will process your Personal Data as part of their contracts with us:
      <br />
      <br />
      ●(+S/M) In case you wish to receive more information about sharing your Data with third
      parties, please contact us by email at info@vivianlab.com.
      <br />
      <br />
      10. How do we ensure that Processors respect your Data?
      <br />
      Those performing the processing on our behalf have agreed and contractually committed to the
      Company: ●to maintain confidentiality,
      <br />
      ●not to send your Data to third parties without the Company's permission,
      <br />
      ●take appropriate security measures,
      <br />
      ●to comply with the legal framework for the protection of personal data and in particular
      Regulation 979/2016/EU (otherwise known as GDPR).
      <br />
      11. Data Transfer <br /> <br />
      The personal data we collect (or process) in the context of our Websites and Apps will be
      stored within the European Union. However, some of the Data recipients with whom the Company
      shares your Personal Data may be located in countries other than the one in which your
      Personal Data was originally collected. The legislation in those countries may not provide the
      same level of data protection compared to the country that originally provided your Personal
      Data. However, when we transfer your Personal Data to recipients in other countries, including
      the US, Switzerland, etc. , we are committed to protecting your Personal Data as described in
      this Privacy Policy and in accordance with applicable law.
      <br />
      We take measures to comply with applicable legal requirements for the transfer of personal
      data to recipients in countries outside the European Economic Area or Switzerland that do not
      ensure an adequate level of protection. We use various measures to ensure that your Personal
      Data transferred to these countries is adequately protected under data protection rules. These
      include signing the Contractual Clauses, certifying that the recipient has adopted the
      European binding rules or observes the Privacy Shield Shield ) between EU-US and
      Switzerland-US.
      <br /> <br />
      12. How long do we keep your Data? <br /> <br />
      We retain your Personal Data for as long as necessary to fulfill the purposes set out in this
      Privacy Policy (unless a longer retention period is required by applicable law). Generally
      this means that we will retain your Personal Data for as long as you have an account with our
      Company. In relation to your Personal Data relating to our offered services and related
      products, we retain this data for a longer period in order to comply with our legal
      obligations (such as tax and commercial law and for warranty purposes where applicable). At
      the end of this retention period, your data will be completely deleted or anonymized, for
      example by aggregating with other data, so that it can be used in a de-identified way for
      statistical analysis and business planning. <br />
      Some examples of Customer Data retention periods: <br />
      ●Services <br />
      When you use a service, we will keep the personal data you have given us for five years so
      that we can comply with our legal and contractual obligations. <br />
      ●Newsletter <br />
      Your declaration of consent for sending a newsletter is kept for as long as the newsletter is
      sent to you by the Company and in any case no more than six months from the cessation of its
      sending.
      <br />
      <br />
      13. Is your Data secure?
      <br />
      We are committed to safeguarding your Personal Data. <br />
      Recognizing the importance of the security of your Personal Data, we have taken all
      appropriate organizational and technical measures to secure and protect your Data from any
      form of accidental or unlawful processing. We use the most modern and advanced methods to
      ensure maximum security. <br />
      Our site uses the most modern and secure protocols in order to secure online commercial
      transactions. This encrypts all Data you provide, including your credit card number, name and
      address, so that it cannot be decrypted or changed in transit over the Internet. <br />
      Additionally, the information used to identify you as an account user is two: the Login Code (
      Username ) and the Personal Secret Security Code ( Password ). Each time you register your
      details, you are given access to your personal account. This specific process is achieved
      safely through encryption during their transfer to the internet and the Company's servers .
      According to the same standards, you are given the possibility to change your Personal Secret
      Security Code ( Password ) as often as you wish. After entering the desired code, the new code
      is coded and stored in the Company's systems. For this reason, the only person who knows your
      password is yourself and you are solely responsible for keeping the password confidential from
      third parties. <br />
      These measures are reviewed and amended when deemed necessary.
      <br /> <br />
      14. What are your rights? <br />
      You have the right to access your Personal Data. <br />
      This means that you have the right to be informed by us if we are processing your Data. If we
      process your Data, you can ask to be informed about the purpose of the processing, the type of
      your Data we keep, to whom we give it, how long we store it, whether automated decision-making
      takes place, but also about your other rights, such as correction, deletion of data,
      restriction of processing and filing a complaint with the Personal Data Protection Authority.
      You have the right to correct inaccurate personal data. <br />
      If you find that there is an error in your Data you can submit a request to us to correct it
      (eg correct a name or update a change of address). <br />
      You have a right to erasure/right to be forgotten. <br />
      You can ask us to delete your data if it is no longer necessary for the above-mentioned
      processing purposes or you wish to revoke your data in the event that this is the only legal
      basis. <br />
      You have the right to portability of your Data. <br />
      You can ask us to receive the Data you have provided in readable form or ask us to pass it on
      to another controller. <br />
      You have the right to restrict processing. <br />
      You can ask us to restrict the processing of your Data pending the consideration of your
      objections to the processing. <br />
      You have the right to object and withdraw consent to the processing of your Data. <br />
      You can object to the processing of your Data and we will stop processing your Data, unless
      there are other compelling and legitimate reasons that override your right. If you have given
      your consent to the collection, processing and use of your personal data, you can withdraw
      your consent at any time with future effect: <br />
      <br /> <br />
      Choosing not to receive Marketing Communications . <br />
      You can choose not to receive marketing communications by changing your email and sms
      registrations, clicking the delete link or following the instructions included in the message.{' '}
      <br />
      Alternatively you can contact us using the contact details we give you in the relevant term
      below. <br />
      In case we rely on our legitimate interest: In cases where we process your personal data based
      on our legitimate interest, you can ask us to stop for reasons related to your personal
      situation. We must then do so unless we believe we have a compelling legitimate reason to
      continue processing your Personal Data. <br />
      <br /> <br />
      15. How can you exercise your rights? <br />
      In order to exercise your rights, you can submit a relevant request to us at the electronic
      address info@vivianlab.com. site entitled "Exercise of Right" and we will examine it and
      answer you as soon as possible. <br /> <br />
      Exceptionally: <br />
      ●if you wish to correct your Data in your user account, you can log in to it and make any
      correction/change without requiring the submission of a Request. <br />
      ●if you wish to withdraw your consent to send a newsletter, you can do so by selecting the
      link "To delete from the " newsletter mailing list " click here" located at the bottom of each
      newsletter . <br />
      ●if you wish not to receive web push notifications from the Company, you can disable the
      option from your browser settings.
      <br /> <br />
      Identity check <br />
      To protect the confidentiality of your information, we will ask you to verify your identity
      before proceeding with any request you make under this Privacy Policy. If you have authorized
      a third party to make a request on your behalf, we will ask them to demonstrate that they have
      your permission to act for this purpose. <br />
      <br /> <br />
      16. When do we respond to your Requests? <br />
      We respond to your Requests free of charge without delay, and in any case within (1) one month
      of receiving your request. However, if your Request is complex or there are a large number of
      your Requests, we will inform you within the month if we need to obtain an extension of
      another (2) two months within which we will respond to you.
      <br />
      17. What is the applicable law when we process your Data? Applicable Law is Greek Law, as
      formulated in accordance with the General Regulation for the Protection of Personal Data
      2016/679/EU, and in general the current national and European legislative and regulatory
      framework for the protection of personal data. <br /> <br />
      Any dispute arising out of or related to the protection of your Personal Data shall be subject
      to arbitration in accordance with the Mediation Regulation of the European Mediation and
      Arbitration Organization (EOMIA). In the event that the dispute or part thereof is not
      resolved through mediation, the dispute or the unresolved part thereof shall be resolved
      exclusively, finally and irrevocably by an arbitral tribunal, appointed and conducting the
      arbitration in accordance with the EODID Arbitration Rules. <br /> <br />
      In any case of disputing the above, the competent courts are the competent courts of the city
      of Athens.
      <br /> <br />
      18. Where can you go if we breach the applicable law for the protection of your Personal Data?
      You have the right to submit a complaint to the Personal Data Protection Authority (postal
      address Kifisias 1-3, P.O. 115 23, Athens, tel . 210. 6475600, e- mail address (e- mail )
      contact@dpa.gr), if you consider that the processing of your Personal Data violates the
      applicable national and regulatory framework for the protection of personal data.
      <br /> <br />
      19. How will you be informed of any changes to this Policy? We update this Privacy Policy
      whenever necessary. If there are significant changes to the Privacy Policy or the way we use
      your Personal Data, we will post an update to this on our website before the changes take
      effect and we will notify you as soon as possible. <br />
      We encourage you to read this Policy periodically to know how your Data is protected. This
      privacy policy was last modified 16th/November/2023. <br />
      We remain at your disposal for any further information and concerns that may arise from this
      Statement - Privacy Policy and please contact our Company in the future info@vivianlab.com.
      <br />
    </div>
  );
}

export default PrivacyEnglish;
