import React from 'react';

function PrivacyGreek() {
  return (
    <div>
      <h2>Πολιτική Απορρήτου</h2>
      <h4>Επεξεργασία Προσωπικών Δεδομένων</h4>
      <h4>Για τις ανάγκες του παρόντος δηλώνονται τα κάτωθι διευκρινιστικά:</h4>
      <p>
        Η εδώ αναφερόμενη εταιρεία με δ.τ. «VIVIAN LAB» ορίζεται ως «ο Υπεύθυνος Επεξεργασίας» και
        επομένως, μεταξύ άλλων, συλλέγει, αποθηκεύει, χρησιμοποιεί, επεξεργάζεται και δρομολογεί
        προσωπικά δεδομένα που υπεισέρχονται στη σφαίρα ενημέρωσή της και εκτέλεσης εντελών όταν το
        Υποκείμενο των δεδομένων επισκέπτεται, εγγράφεται, χρησιμοποιεί καθ’ οιονδήποτε τρόπο τους
        διαδικτυακούς τόπους της Εταιρείας – Δικτυακοί Τόποι – καθώς και τις κινητές / τεχνολογικές
        εφαρμογές της Εταιρείας – Applications (Apps).
      </p>
      <p>
        Καθώς η προστασία των δεδομένων προσωπικού χαρακτήρα είναι εξαιρετικά σημαντική για την
        εταιρεία μας το παρόν ορίζει τις υποχρεώσεις, τον τρόπο χρήσης, κοινοποίησης και προστασίας
        των δεδομένων που λαμβάνουμε καθώς και πως μπορούμε να επιλύσουμε πάσης φύσεως ζήτημα δια
        μέσω της επικοινωνίας μεταξύ μας.
      </p>
      <p>1. Ορισμοί</p>
      <br />
      1.1 Νομοθεσία περί Προστασίας Δεδομένων σημαίνει κάθε νόμος που αφορά στην επεξεργασία
      δεδομένων προσωπικού χαρακτήρα, στην ιδιωτική ζωή και την ασφάλεια, συμπεριλαμβανομένων, χωρίς
      περιορισμό, του Κανονισμού (ΕΕ) 2016/679 «για την προστασία των φυσικών προσώπων έναντι της
      επεξεργασίας δεδομένων προσωπικού χαρακτήρα και για την ελεύθερη κυκλοφορία των δεδομένων
      αυτών και την κατάργηση της οδηγίας 95/46/ΕΚ (Γενικός Κανονισμός για την Προστασία Δεδομένων)»
      («ΓΚΠΔ»), της Οδηγίας 2002/58/ΕΚ «για την προστασία ιδιωτικής ζωής στις ηλεκτρονικές
      επικοινωνίες», όπως ενσωματώνονται, ισχύουν και εφαρμόζονται στην ελληνική έννομη τάξη και
      όπως εκάστοτε τροποποιούνται, ή άλλους εφαρμοστέους ή σε αντικατάσταση των ανωτέρω διεθνείς ή
      εθνικούς νόμους ή κανόνες που αφορούν στην προστασία δεδομένων προσωπικού χαρακτήρα, αλλά και
      σχετικούς κανονισμούς, οδηγίες ή κατευθυντήριες γραμμές αρμόδιων διοικητικών αρχών, όπως η
      Ελληνική Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.
      <br />
      <br />
      1.2 «Υπεύθυνος επεξεργασίας», «εκτελών την επεξεργασία», «υποκείμενο των δεδομένων», «δεδομένα
      προσωπικού χαρακτήρα» και «επεξεργασία» θα έχουν το νόημα που τους αποδίδεται στην εφαρμοστέα
      Νομοθεσία περί Προστασίας Δεδομένων.
      <br />
      <br />
      1.3 Δεδομένα Προσωπικού Χαρακτήρα σημαίνει το σύνολο των δεδομένων προσωπικού χαρακτήρα, όπως
      αυτά ορίζονται κατά την εφαρμοστέα Νομοθεσία περί Προστασίας Δεδομένων, τα οποία
      γνωστοποιούνται ή ενδέχεται να γνωστοποιηθούν στον Εκτελούντα την επεξεργασία ή να τεθούν στη
      διάθεσή του για τους σκοπούς και στο πλαίσιο της εκάστοτε επικοινωνίας, ή/και τα οποία ο
      Εκτελών την επεξεργασία (ή/και ο εκάστοτε υπεργολάβος του) συλλέγει, αποθηκεύει ή καθ’
      οιονδήποτε άλλον τρόπο επεξεργάζεται για λογαριασμό της Εταιρείας, ως εκτελών την επεξεργασία
      στο πλαίσιο της εκάστοτε επικοινωνίας και συνεργασίας.
      <br />
      <br />
      1.4 Υπηρεσίες: Οι αναφερόμενες στο επίσημο site της Εταιρείας μας αναφερόμενες και
      προσφερόμενες προς χρήση.
      <br />
      <br />
      1.5. Διαδικτυακοί Τόποι: Αφορά το ‘www.vivianlab.com’ που είναι ο δικτυακός τόπος της
      Εταιρείας μας, η οποία διατηρεί ηλεκτρονική πλατφόρμα πραγματοποίησης παραγγελιών από
      συνεργαζόμενα καταστήματα υγειονομικού ενδιαφέροντος, παρέχοντας στους πελάτες της άμεση
      εξυπηρέτηση και μεταφορά των ως άνω παραγγελιών μέσω της ηλεκτρονικής πλατφόρμας της Εταιρείας
      από δίκτυο ανεξάρτητων διανομέων και συνεργαζόμενων καταστημάτων.
      <br />
      <br />
      1.6. Επεξεργασία Προσωπικών Δεδομένων είναι κάθε πράξη ή σειρά πράξεων που πραγματοποιείται με
      ή χωρίς τη χρήση αυτοματοποιημένων μέσων, σε δεδομένα προσωπικού χαρακτήρα ή σε σύνολα
      δεδομένων προσωπικού χαρακτήρα, όπως η συλλογή, καταχώριση, οργάνωση, διάρθρωση, αποθήκευση,
      προσαρμογή ή μεταβολή, ανάκτηση, αναζήτηση πληροφοριών, χρήση, κοινολόγηση με διαβίβαση,
      διάδοση ή κάθε άλλη μορφή διάθεσης, συσχέτιση ή συνδυασμός, περιορισμός, διαγραφή ή
      καταστροφή.
      <br />
      <br />
      2. Σκοπός Επεξεργασίας και Διάρκεια
      <br />
      2.1 Η Υπεύθυνη Επεξεργασίας πριν παραδώσει δεδομένα στον Εκτελούντα την Επεξεργασία που
      εμπίπτουν στο πεδίο εφαρμογής της σχετικής νομοθεσίας για την Προστασία Δεδομένων και για τα
      οποία η Υπεύθυνη Επεξεργασίας είναι κυρίως υπεύθυνη ή/και υπόλογη σύμφωνα με τη σχετική
      νομοθεσία για την Προστασία Δεδομένων (τα "Προσωπικά Δεδομένα του Υπευθύνου Επεξεργασίας")
      δυνάμει ή σε σχέση με τους Όρους και Προϋποθέσεις του παρόντος ή οποιασδήποτε Συμφωνίας πρέπει
      να έχει λάβει από το υποκείμενο των Δεδομένων τη συγκατάθεση που απαιτείται για τη διαβίβαση.
      Το ίδιο ισχύει για την περίπτωση που ο Εκτελών την Επεξεργασία συλλέγει και επεξεργάζεται εν
      γένει δεδομένα προσωπικού χαρακτήρα που λαμβάνει απευθείας από το υποκείμενο για λογαριασμό
      της Υπεύθυνης Επεξεργασίας.
      <br />
      <br />
      2.2 Η Εταιρεία θα επεξεργάζεται τα Προσωπικά Δεδομένα με σκοπό την παροχή των προσφερόμενων
      Υπηρεσιών. Ακολούθως, θα χρησιμοποιεί τα Δεδομένα Προσωπικού Χαρακτήρα μόνο όταν είναι
      απολύτως αναγκαία για την εκπλήρωση των υποχρεώσεών της, σύμφωνα με τους Όρους και
      Προϋποθέσεις που ο Νόμος επιβάλλει.
      <br />
      <br />
      2.3 Οι ενέργειες επεξεργασίας θα διαρκέσουν για το χρονικό διάστημα που απαιτείται για την
      ολοκλήρωση των Υπηρεσιών.
      <br />
      <br />
      2.4 Πάντοτε τα Προσωπικά Δεδομένα θα προστατεύονται από την Εταιρεία ως Υπεύθυνη Επεξεργασίας
      με τον χαρακτήρα Εμπιστευτικών προστατευόμενων Πληροφοριών.
      <br />
      <br />
      3. Είναι υποχρεωτική η παραχώρηση των Προσωπικών Δεδομένων σας;
      <br />
      Η παραχώρηση των Δεδομένων στην Εταιρεία μπορεί να είναι απαραίτητη για να επιτευχθούν οι
      σκοποί που προσδιορίζονται στην παρούσα Πολιτική Απορρήτου ή να είναι προαιρετική.
      <br />
      <br />
      Η υποχρεωτική ή προαιρετική φύση της παραχώρησης Δεδομένων επισημαίνεται με έναν αστερίσκο (*)
      δίπλα στα προσωπικά δεδομένα υποχρεωτικού χαρακτήρα.
      <br />
      <br />
      Εάν αρνηθείτε να παραχωρήσετε τα στοιχεία που επισημαίνονται ως υποχρεωτικά στους Δικτυακούς
      Τόπους, θα είναι αδύνατο να επιτευχθεί ο βασικός σκοπός για τον οποίο συλλέγονται τα
      συγκεκριμένα Δεδομένα, και μπορεί, για παράδειγμα, να καταστεί αδύνατη για την Εταιρεία η
      εκπλήρωσης της υπηρεσίας μεταφοράς ή η παροχή των άλλων υπηρεσιών που είναι διαθέσιμες στους
      Δικτυακούς Τόπους της.
      <br />
      <br />
      Η παραχώρηση επιπρόσθετων Δεδομένων στην Εταιρεία, πέραν εκείνων που επισημαίνονται ως
      υποχρεωτικά, είναι προαιρετική και δεν επιφέρει συνέπειες σχετικά με τους κύριους σκοπούς της
      συλλογής Δεδομένων, αφού η παραχώρησή τους χρησιμεύει αποκλειστικά στη βελτιστοποίηση της
      ποιότητας των παρεχόμενων από εμάς υπηρεσιών.
      <br />
      <br />
      4. Ποια Προσωπικά Δεδομένα σας συλλέγουμε;
      <br />
      Φροντίζουμε να συλλέγουμε μόνο τα απολύτως απαραίτητα Προσωπικά Δεδομένα σας, τα οποία είναι
      κατάλληλα και σαφή για τον σκοπό που προορίζονται. Αυτά τα Δεδομένα περιλαμβάνουν τα εξής:
      <br />
      α. Δεδομένα που μας παρέχετε κατά την εγγραφή σας και τη δημιουργία λογαριασμού χρήστη στους
      Δικτυακούς Τόπους ή στα Apps της Εταιρείας, μέσω του διαδικτύου ή του κινητού σας ή μέσω της
      προσωπικής σας επαφής με την εταιρεία μας και συγκεκριμένα δεδομένα όπως ηλεκτρονική διεύθυνση
      (e-mail)* και κωδικό πρόσβασης/login password (ως υποχρεωτικά) και όνομα, επίθετο, ταχυδρομική
      διεύθυνση, αριθμό τηλεφώνου (ως προαιρετικά):
      <br />
      β. Δεδομένα και πληροφορίες που μας παρέχετε μέσα από τις μεταξύ μας συναλλαγές (υπηρεσίες,
      επιλογές, συνέργειες κ.λπ.) και τη μεταξύ μας επικοινωνία ( του ιστότοπου, των συνεργατών, του
      τηλεφώνου, του ηλεκτρονικού ταχυδρομείου ή μέσω οποιουδήποτε άλλου τρόπου). Για παράδειγμα,
      συλλέγουμε σημειώσεις από τις συνομιλίες μας μαζί σας, λεπτομέρειες σχετικά με τυχόν παράπονα
      ή σχόλια που κάνετε, λεπτομέρειες σχετικά με τις υπηρεσίες που επιλέγετε, προτιμήσεις που
      επαναλαμβάνονται, λίστα επιλογών και υπηρεσιών που επιθυμείτε (wish list), εξαργυρώσεις
      κουπονιών, ποιες από τις ιστοσελίδες μας επισκέπτεστε και πώς και πότε επικοινωνείτε μαζί μας.
      <br />
      γ. Δεδομένα που αφορούν στον τρόπο πληρωμής για τις συναλλαγές που πραγματοποιείτε μαζί μας.
      <br />
      δ. Δεδομένα που μας παρέχετε όταν κάνετε εγγραφή σε newsletter μας ή πάσης φύσεως ενημερωτικό
      υλικό μας.
      <br />
      ε. Δεδομένα για τις επιλογές και τις υπηρεσίες που κατά προτίμηση επιλέγετε συνήθως.
      Προκειμένου να σας προτείνουμε υπηρεσίες του ενδιαφέροντός σας και να βελτιώσουμε ακόμα
      περισσότερο την εμπειρία συνεργασίας μαζί μας και εν γένει συναλλαγών σας μέσω της εταιρείας
      μας και της χρήσης του ιστότοπού μας. Φυσικά, έχετε πάντα την επιλογή να μην μοιράζεστε τέτοια
      στοιχεία μαζί μας.
      <br />
      στ. Στοιχεία επισκεψιμότητας της ιστοσελίδας μας.
      <br />
      ζ. Πληροφορίες που συλλέγονται από τη χρήση cookies στο πρόγραμμα περιήγησής σας. Μάθετε
      περισσότερα σχετικά με τον τρόπο χρήσης των cookies εδώ (Link).
      <br />
      η. Για να προσφέρουμε την καλύτερη δυνατή εμπειρία ιστοτόπου, συλλέγουμε τεχνικές πληροφορίες
      σχετικά με τη σύνδεσή σας στο διαδίκτυο και το πρόγραμμα περιήγησης, καθώς και τον κωδικό
      χώρας και τηλεφώνου όπου βρίσκεται ο υπολογιστής σας, τις ιστοσελίδες που εμφανίζονται κατά
      την επίσκεψή σας, τις διαφημίσεις στις οποίες κάνετε κλικ και όποιους όρους αναζήτησης μπήκες.
      <br />
      θ. Το όνομα χρήστη του κοινωνικού μέσου δικτύωσής σας, εάν αλληλεπιδράτε μαζί μας μέσω αυτών
      των καναλιών, για να μας βοηθήσετε να απαντήσουμε στα σχόλια, τις ερωτήσεις ή τα σχόλιά σας.
      ι. Μορφωτικά στοιχεία, όπως σπουδές, δεξιότητες, γνώση ξένων γλωσσών, επαγγελματική εμπειρία
      (μόνο στις περιπτώσεις που απαντάτε σε αγγελία για εύρεση εργασίας)
      <br />
      <br />
      <p>
        <h4>Ενσωμάτωση Google Meet και Google Calendar</h4>
        Εκτιμούμε ιδιαίτερα την ιδιωτικότητά σας και είμαστε πλήρως αφοσιωμένοι στην προστασία των
        προσωπικών σας πληροφοριών. Η πλατφόρμα μας ενσωματώνεται με το Google Meet και το Google
        Calendar για να παρέχει μια ομαλή, ασφαλή και φιλική προς το χρήστη εμπειρία για τον
        προγραμματισμό και τη διαχείριση εικονικών συναντήσεων. Αυτή η ενσωμάτωση έχει σχεδιαστεί
        για να απλοποιεί τη ροή εργασίας σας, επιτρέποντάς σας να κανονίζετε συναντήσεις απευθείας
        μέσα από την πλατφόρμα μας χωρίς την ανάγκη για εξωτερικά εργαλεία.
        <br />
        <strong>Πώς λειτουργεί η ενσωμάτωση</strong>
        <br />Η ισχυρή ενσωμάτωσή μας με το API του Google Calendar επιτρέπει στους χρήστες
        λογαριασμών Google στην πλατφόρμα μας να δημιουργούν, να ενημερώνουν και να διαγράφουν
        συναντήσεις χωρίς κόπο, με συνδέσμους συναντήσεων που διαχειρίζονται αυτόματα μέσω του
        Google Calendar. Χρησιμοποιώντας το{' '}
        <a href="https://www.googleapis.com/auth/calendar.events.owned">
          https://www.googleapis.com/auth/calendar.events.owned
        </a>
        , εξασφαλίζουμε ομαλή διαδικασία προγραμματισμού διατηρώντας αυστηρά πρότυπα ασφαλείας
        δεδομένων.
        <br />
        <strong>Η ιδιωτικότητά σας μετράει</strong>
        <br />
        Λαμβάνουμε πολύ σοβαρά την προστασία των δεδομένων σας. Σημαντικό είναι ότι δεν κοινοποιούμε
        ή μεταφέρουμε τα δεδομένα σας στο Google σε τρίτους, εκτός από ό,τι είναι απαραίτητο για την
        ενίσχυση και την παροχή αυτής της λειτουργικότητας προγραμματισμού. Η χρήση του API του
        Google Calendar από εμάς περιορίζεται αυστηρά στη βελτίωση της εμπειρίας σας στον
        προγραμματισμό και τη διαχείριση συναντήσεων.
        <br />
        <strong>Ασφάλεια δεδομένων και έλεγχος</strong>
        <br />
        Διατηρείτε πλήρη έλεγχο στις συναντήσεις σας και η ενσωμάτωσή μας συμβάλλει στην αύξηση της
        παραγωγικότητας και της αποτελεσματικότητας χωρίς να διακυβεύεται η ιδιωτικότητά σας.
        Αποκτάμε μόνο τις απαραίτητες πληροφορίες από το Google Calendar σας για να παρέχουμε την
        επιδιωκόμενη λειτουργία, εξασφαλίζοντας ότι όλες οι αλληλεπιδράσεις παραμένουν ασφαλείς.
        <br />
        <strong>Κοινοποίηση δεδομένων σε τρίτους</strong>
        <br />
        Δεν κοινοποιούμε τα δεδομένα σας σε τρίτους, εκτός από την υποστήριξη της λειτουργικότητας
        του Google Meet και του Google Calendar όπως περιγράφεται. Για περισσότερες λεπτομέρειες
        σχετικά με τον τρόπο με τον οποίο η Google εξασφαλίζει την ασφαλή κοινοποίηση των δεδομένων
        των χρηστών, ανατρέξτε στο
        <a href="https://support.google.com/">Άρθρο Υποστήριξης της Google</a>.
        <br />
        <strong>Δέσμευση για Ασφαλή Επικοινωνία</strong>
        <br />Η ενσωμάτωση του Google Meet είναι μέρος της συνεχούς δέσμευσής μας για την παροχή
        μιας ασφαλούς, αποδοτικής και προσανατολισμένης στον χρήστη εμπειρίας. Με την κεντροποίηση
        της διαχείρισης συναντήσεών σας στην πλατφόρμα μας, βοηθάμε στην απλοποίηση των διαδικασιών
        επικοινωνίας σας, ενώ διατηρούμε αυστηρές πρακτικές προστασίας δεδομένων. Εάν έχετε
        οποιεσδήποτε ερωτήσεις ή ανησυχίες σχετικά με την ιδιωτικότητά σας και την ενσωμάτωση μας με
        τις υπηρεσίες της Google, παρακαλούμε να επικοινωνήσετε μαζί μας.
      </p>
      <p>
        <strong>5. Πώς χρησιμοποιούμε τα Προσωπικά σας Δεδομένα;</strong>
        <br />
        Όπου είναι εφαρμόσιμο, χρησιμοποιούμε τα δεδομένα σας:
        <br />● Για την ολοκλήρωση επιλογών υπηρεσίας: Η Εταιρεία επεξεργάζεται τα δεδομένα σας για
        να εκπληρώσει τη συμβατική της σχέση, να επεξεργαστεί την επιλογή υπηρεσίας, να παρέχει
        εξυπηρέτηση πελατών, να συμμορφωθεί με νομικές υποχρεώσεις, να εκπληρώσει απαιτήσεις
        πληρωμής, να εγείρει ή να ασκήσει νομικές αξιώσεις. Αν δεν συλλέξουμε τα δεδομένα σας κατά
        την ολοκλήρωση της επιλογής σας (μέσω της υπηρεσίας τηλεφώνου μας ή μέσω του διαδικτυακού
        μας καταστήματος), δεν θα μπορέσουμε να επεξεργαστούμε την επιλογή σας και να συμμορφωθούμε
        με τις νομικές μας υποχρεώσεις. Σημειώνουμε ότι μπορεί να είναι απαραίτητο να μεταφέρουμε τα
        δεδομένα σας σε τρίτους για την υπηρεσία και την επεξεργασία της υπηρεσίας που έχετε
        προτιμήσει (Για πληροφορίες σχετικά με τον τρόπο με τον οποίο παρέχουμε προσωπικά δεδομένα
        σε τρίτους, ανατρέξτε στους σχετικούς όρους παρακάτω).
      </p>
      ●Για τη Δημιουργία Λογαριασμού Χρήστη: Η Εταιρεία επεξεργάζεται Δεδομένα σας προκειμένου να
      σας παρέχει τις λειτουργίες λογαριασμού και να διευκολυνθεί η σύναψη παροχής υπηρεσιών.
      <br />
      ●Για την Επικοινωνία: Η Εταιρεία χρησιμοποιεί Δεδομένα σας για να απαντήσει στα
      αιτήματα/ερωτήματα που υποβάλλετε, στα αιτήματα επιστροφής χρημάτων ή/και στα τυχόν παράπονα.
      Οι πληροφορίες που μοιράζεστε μαζί μας, μας δίνουν τη δυνατότητα να διαχειριστούμε τα αιτήματά
      σας και να σας απαντήσουμε με τον καλύτερο δυνατό τρόπο. Μπορούμε επίσης να διατηρήσουμε ένα
      αρχείο των ερωτημάτων/αιτημάτων σας προς εμάς ώστε να ανταποκρινόμαστε καλύτερα σε οποιαδήποτε
      μελλοντική επικοινωνία. Το κάνουμε αυτό με βάση τις συμβατικές μας υποχρεώσεις προς εσάς, τις
      νομικές μας υποχρεώσεις αλλά και τα νόμιμα συμφέροντά μας ώστε να σας παρέχουμε την καλύτερη
      δυνατή εξυπηρέτηση και να είμαστε σε θέση να βελτιώσουμε τις υπηρεσίες μας με βάση τη δική σας
      προσωπική εμπειρία.
      <br />
      ●Για την Αποστολή ενημερωτικού δελτίου (newsletter)/προσφορών: Με τη συγκατάθεσή σας, θα
      χρησιμοποιήσουμε τα Προσωπικά σας Δεδομένα, τις προτιμήσεις και τα στοιχεία των συναλλαγών σας
      για να σας ενημερώνουμε μέσω e-mail, διαδικτύου, τηλεφώνου ή/και μέσω μέσων κοινωνικής
      δικτύωσης για σχετικές υπηρεσίες, συμπεριλαμβανομένων εξατομικευμένων / προσωποποιημένων
      προσφορών κ.λπ. Φυσικά έχετε τη δυνατότητα να ανακαλέσετε τη συγκατάθεση αυτή οποιαδήποτε
      στιγμή.
      <br />
      ●Για Web push notifications: Ανάλογα με την πλοήγησή σας, μπορείτε να λαμβάνετε, έχοντας
      πρoηγουμένως δώσει τη συγκατάθεσή σας, ειδοποιήσεις για τις προσφορές μας, τα νέα, τη wish
      list σας και τις κύριες προτιμήσεις και επιλογές σας. Φυσικά έχετε τη δυνατότητα να
      ανακαλέσετε τη συγκατάθεση αυτή οποιαδήποτε στιγμή.
      <br />
      ●Για Συμμετοχή σε πρόγραμμα επιβράβευσης: Η Εταιρεία μπορεί να επεξεργάζεται Δεδομένα σας για
      τις ανάγκες της συμμετοχής σας σε πρόγραμμα επιβράβευσης, δηλαδή τόσο της εξέτασης της αίτησης
      συμμετοχής σας, όσο και της συγκέντρωσης και εξαργύρωσης πόντων και γενικότερα της απόλαυσης
      προνομίων πελάτη, όπως αυτά θα αναλύονται στους όρους συμμετοχής του προγράμματος
      επιβράβευσης. Έτσι είμαστε σε θέση να σας προσφέρουμε εξατομικευμένες προσφορές που σας
      ενδιαφέρουν. Φυσικά, είστε ελεύθεροι να επιλέξετε αν θα επωφεληθείτε από αυτές.
      <br />
      ●Για την Ανάπτυξη και βελτίωση των επιλογών, των προσφορών, της λειτουργίας και των υπηρεσιών
      που σας παρέχουμε. Το κάνουμε αυτό με βάση τα νόμιμα επιχειρηματικά μας συμφέροντα.
      <br />
      ●Γιατί θέλουμε να σας προσφέρουμε προσφορές και προτάσεις που είναι πιο σχετικές με τα
      ενδιαφέροντα και τις ανάγκες σας.
      <br />
      ●Για να εξασφαλίσουμε ότι θα σας εμφανίζεται πάντα το πιο ενδιαφέρον περιεχόμενο στους
      Δικτυακούς Τόπους ή στα Apps μας, θα χρησιμοποιήσουμε τα Δεδομένα που μας έχετε παράσχει
      δίνοντάς μας τη συγκατάθεσή σας να λαμβάνετε ειδοποιήσεις Apps ή - για τους Δικτυακούς μας
      Τόπους – τη συγκατάθεσή σας για την τοποθέτηση cookies στη συσκευή σας. Για παράδειγμα,
      ενδέχεται να εμφανίσουμε μια λίστα με υπηρεσίες που κοιτάξατε πρόσφατα ή να σας προσφέρουμε
      συστάσεις με βάση το ιστορικό επιλογών σας και οποιαδήποτε άλλα Δεδομένα έχετε μοιραστεί μαζί
      μας.
      <br />
      ●Για να σας στείλουμε αιτήματα έρευνας και αξιολόγησης προκειμένου να μπορούμε να βελτιώσουμε
      τις υπηρεσίες μας. Αυτά τα μηνύματα δεν θα περιλαμβάνουν διαφημιστικό περιεχόμενο και δεν
      απαιτούν προηγούμενη συγκατάθεση όταν αποστέλλονται με μήνυμα ηλεκτρονικού ταχυδρομείου ή με
      μήνυμα κειμένου (SMS). Έχουμε νόμιμο συμφέρον να το πράξουμε, καθώς αυτό βοηθά τις υπηρεσίες
      μας να είναι πιο συναφείς με εσάς. Φυσικά, είστε ελεύθεροι να αρνηθείτε να λάβετε αυτά τα
      αιτήματα από εμάς ανά πάσα στιγμή ενημερώνοντας τις προτιμήσεις σας στον λογαριασμό σας στο
      διαδίκτυο.
      <br />
      ●Για την προστασία του λογαριασμού σας από απάτες και άλλες παράνομες δραστηριότητες: Αυτό
      περιλαμβάνει τη χρήση των Δεδομένων σας για τη συντήρηση, ενημέρωση και προστασία του
      λογαριασμού σας. Παρακολουθούμε επίσης τη δραστηριότητα περιήγησης μαζί μας για να εντοπίσουμε
      και να επιλύσουμε γρήγορα τυχόν προβλήματα και να προστατέψουμε την ακεραιότητα της
      ιστοσελίδας μας. Όλα τα παραπάνω αποτελούν μέρος του νόμιμου συμφέροντός μας. Για παράδειγμα,
      ελέγχουμε τον κωδικό πρόσβασής σας όταν συνδέεστε και χρησιμοποιούμε αυτοματοποιημένη
      παρακολούθηση διευθύνσεων IP για να εντοπίσουμε πιθανές ψευδείς εισόδους από απροσδόκητες
      τοποθεσίες.
      <br />
      ●Για την επεξεργασία των πληρωμών και την αποτροπή δόλιων συναλλαγών: Το κάνουμε αυτό με βάση
      τα νόμιμα επιχειρηματικά μας συμφέροντα. Αυτό βοηθά επίσης στην προστασία των πελατών μας από
      απάτες και εν γένει περιστατικά cyber crime.
      <br />
      ●Για να συμμορφωθούμε με τις συμβατικές μας προς εσάς υποχρεώσεις ή κατ’ εφαρμογή διατάξεων
      νόμου ή σε εκτέλεση δικαστικών αποφάσεων.
      <br />
      ●Για να σας στείλουμε επικοινωνίες που απαιτούνται από το νόμο ή που είναι απαραίτητες για να
      σας ενημερώσουμε για τις αλλαγές στις υπηρεσίες που σας παρέχουμε. Για παράδειγμα, ενημερώσεις
      σχετικά με αυτές τις ειδοποιήσεις απορρήτου, ειδοποιήσεις ανάκλησης υπηρεσιών / συνεργασιών
      και νομικά απαιτούμενες πληροφορίες σχετικά με τις επιλογές σας. Αυτά τα μηνύματα υπηρεσίας
      δεν θα περιλαμβάνουν διαφημιστικό περιεχόμενο και δεν απαιτούν προηγούμενη συγκατάθεση όταν
      αποστέλλονται με μήνυμα ηλεκτρονικού ταχυδρομείου ή με μήνυμα κειμένου (SMS). Εάν δεν
      χρησιμοποιήσουμε τα προσωπικά σας δεδομένα για τους σκοπούς αυτούς, δεν μπορούμε να
      συμμορφωθούμε με τις νομικές μας υποχρεώσεις.
      <br />
      Σας γνωρίζουμε τέλος ότι η επεξεργασία των Δεδομένων σας διεξάγεται είτε από το ειδικά
      εξουσιοδοτημένο προσωπικό της Εταιρείας, είτε μέσω συστημάτων πληροφορικής και ηλεκτρονικών
      συσκευών από την Εταιρεία και κατ’ εξαίρεση από τρίτους, οι οποίοι, έχοντας δεσμευτεί
      συμβατικά για την τήρηση εμπιστευτικότητας και την προστασία των Δεδομένων σας διεξάγουν
      εργασίες που είναι απαραίτητες για την επίτευξη των σκοπών που συνδέονται αυστηρά με τη χρήση
      των Δικτυακών μας Τόπων, τις υπηρεσίες του και την πώληση προϊόντων μέσω των Δικτυακών μας
      Τόπων. Πληροφορίες σχετικά θα βρείτε παρακάτω στους σχετικούς όρους «Ποιοι είναι οι αποδέκτες
      των Δεδομένων σας; Πώς κοινοποιούνται τα Δεδομένα σας».
      <br />
      <br />
      6. Ποια είναι η νόμιμη βάση επεξεργασίας των Δεδομένων σας από την Εταιρεία;
      <br />
      ●η νομοθεσία για την προστασία των δεδομένων που καθορίζει διάφορους λόγους για τους οποίους
      μια εταιρεία μπορεί να συλλέξει και να επεξεργαστεί τα προσωπικά σας δεδομένα, μεταξύ των
      οποίων και τους όρους της συμβατικής μας σχέσης
      <br />
      ●η συγκατάθεση σας, όπου απαιτείται. Για παράδειγμα όταν επιλέγετε να λαμβάνετε newsletter.
      Κατά τη συλλογή των προσωπικών σας δεδομένων, θα σας ενημερώνουμε πάντα ποια δεδομένα είναι
      απαραίτητα σε σχέση με μια συγκεκριμένη υπηρεσία.
      <br />
      ●τις υποχρεώσεις της Εταιρείας που πηγάζουν από τον νόμο (π.χ. φορολογική νομοθεσία, νομοθεσία
      για το ηλεκτρονικό εμπόριο κ.ά.)
      <br />
      ●το έννομο συμφέρον της Εταιρείας μας. Σε συγκεκριμένες περιπτώσεις, συλλέγουμε τα Δεδομένα
      σας με τρόπο που εύλογα αναμένεται ως μέρος της λειτουργίας της επιχείρησής μας και που δεν
      επηρεάζει ουσιαστικά τα δικαιώματά σας, την ελευθερία ή τα συμφέροντά σας.
      <br />
      7. Ποιοι είναι οι αποδέκτες των Δεδομένων σας ;
      <br />
      <br />
      Πρόσβαση στα Δεδομένα σας έχει το απολύτως απαραίτητο προσωπικό της Εταιρείας, το οποίο έχει
      δεσμευτεί με τήρηση εμπιστευτικότητας και οι συνεργαζόμενες με εμάς επιχειρήσεις ή τρίτοι
      πάροχοι υπηρεσιών, οι οποίες επεξεργάζονται τα Δεδομένα σας ως Εκτελούντες την Επεξεργασία για
      λογαριασμό μας και σύμφωνα με τις εντολές μας.
      <br />
      8. Πώς κοινοποιούνται τα Δεδομένα σας;
      <br />
      <br />
      Κοινοποίηση Δεδομένων από την Εταιρεία μας:
      <br />
      Η Εταιρεία κάνει κοινή χρήση των Δεδομένων σας με:
      <br />
      ●Τρίτους παρόχους υπηρεσιών που επεξεργάζονται προσωπικά δεδομένα για λογαριασμό της
      Εταιρείας, για παράδειγμα (ενδεικτικά αναφέρονται) για την επεξεργασία πιστωτικών καρτών και
      πληρωμών, μεταφορές και παραδόσεις, φιλοξενία, διαχείριση και συντήρηση των δεδομένων μας,
      διανομή email, έρευνα και ανάλυση, διαχείριση προωθητικών ενεργειών, καθώς και διαχείριση
      ορισμένων υπηρεσιών και στοιχείων. Όταν χρησιμοποιούμε τρίτους παρόχους υπηρεσιών συνάπτουμε
      συμφωνίες που τους υποχρεώνουν να εφαρμόζουν κατάλληλα τεχνικά και οργανωτικά μέτρα για την
      προστασία των προσωπικών σας δεδομένων.
      <br />
      ●Άλλους τρίτους φορείς, στον βαθμό που απαιτείται για τους εξής σκοπούς: (i) συμμόρφωση μετά
      από αίτημα οργάνου της Ελληνικής Πολιτείας, δικαστική απόφαση ή ισχύοντα νόμο, (ii) αποτροπή
      παράνομων χρήσεων των Δικτυακών Τόπων και Apps μας ή παραβιάσεων των Όρων Χρήσης των Δικτυακών
      Τόπων και Apps μας και των πολιτικών μας, (iii) δική μας προστασία από αξιώσεις τρίτων, και
      (iv) συμβολή στην αποτροπή ή έρευνα περιπτώσεων απάτης (π.χ. παραχάραξη).
      <br />
      ●άλλους τρίτους στους οποίους εσείς οι ίδιοι έχετε δώσει τη συγκατάθεσή σας.
      <br />
      Κοινοποίηση Δεδομένων από εσάς:
      <br />
      ●Όταν χρησιμοποιείτε ορισμένα στοιχεία μέσων κοινωνικής δικτύωσης στους Δικτυακούς Τόπους ή
      Apps μας, μπορείτε να δημιουργήσετε ένα δημόσιο προφίλ το οποίο να περιλαμβάνει πληροφορίες
      όπως όνομα χρήστη, εικόνα προφίλ και πόλη. Μπορείτε επίσης να κάνετε κοινή χρήση περιεχομένου
      με τους φίλους σας ή το ευρύ κοινό, συμπεριλαμβανομένων πληροφοριών για την αλληλεπίδρασή σας
      με την Εταιρεία. Σας ενθαρρύνουμε να χρησιμοποιείτε τα εργαλεία που σας παρέχουμε για τη
      διαχείριση της κοινής χρήσης στα μέσα κοινωνικής δικτύωσης της Εταιρείας με σκοπό τον έλεγχο
      των πληροφοριών που καθιστάτε διαθέσιμες μέσω των στοιχείων μέσων κοινωνικής δικτύωσης της
      Εταιρείας.
      <br />
      9. Ποια η πολιτική που εφαρμόζουμε με τους τρίτους Εκτελούντες την Επεξεργασία των Δεδομένων
      σας σύμφωνα με τα ανωτέρω:
      <br />
      <br />
      ●Παρέχουμε μόνο τις πληροφορίες που χρειάζονται για την εκτέλεση των συγκεκριμένων υπηρεσιών
      τους.
      <br />
      ●Μπορούν να χρησιμοποιήσουν τα Δεδομένα σας μόνο για τους ακριβείς σκοπούς που καθορίζουμε στη
      σύμβασή μας μαζί τους.
      <br />
      ●Συνεργαζόμαστε στενά μαζί τους για να διασφαλίσουμε ότι το απόρρητό σας είναι σεβαστό και
      προστατεύεται ανά πάσα στιγμή.
      <br />
      ●Αν σταματήσουμε να χρησιμοποιούμε τις υπηρεσίες τους, οποιαδήποτε από τα δεδομένα που
      κατέχουν θα διαγραφούν ή θα καταστούν ανώνυμα.
      <br />
      Για να βελτιώσουμε την εμπειρία σας ως πελάτη στους Δικτυακούς μας Τόπους και Apps,
      χρησιμοποιούμε τις ακόλουθες εταιρείες, οι οποίες θα επεξεργαστούν τα Προσωπικά σας Δεδομένα
      ως μέρος των συμβάσεών τους μαζί μας:
      <br />
      ●(+ όλα τα SM)
      <br />
      Σε περίπτωση που επιθυμείτε να λάβετε περισσότερες πληροφορίες σχετικά με την κοινοποίηση των
      Δεδομένων σας σε τρίτους παρακαλούμε επικοινωνήστε μαζί μας με email στο …….(link)
      <br />
      <br />
      10. Πώς εξασφαλίζουμε ότι οι Εκτελούντες την Επεξεργασία σέβονται τα Δεδομένα σας;
      <br />
      Οι Εκτελούντες την επεξεργασία για λογαριασμό μας έχουν συμφωνήσει και δεσμευτεί συμβατικά με
      την Εταιρία: ●να τηρούν εχεμύθεια,
      <br />
      ●να μη στέλνουν σε τρίτους Δεδομένα σας χωρίς την άδεια της Εταιρείας,
      <br />
      <br />
      ●να λαμβάνουν κατάλληλα μέτρα ασφάλειας,
      <br />
      <br />
      ●να συμμορφώνονται με το νομικό πλαίσιο για την προστασία των προσωπικών δεδομένων και ιδίως
      τον Κανονισμό 979/2016/ΕΕ (άλλως GDPR).
      <br />
      <br />
      11. Μεταφορά Δεδομένων
      <br />
      <br />
      Τα προσωπικά δεδομένα που συλλέγουμε (ή επεξεργαζόμαστε) στο πλαίσιο των Δικτυακών Τόπων και
      Apps μας θα αποθηκεύονται εντός Ευρωπαϊκής Ένωσης. Ωστόσο, μερικοί από τους παραλήπτες των
      Δεδομένων με τους οποίους η Εταιρία μοιράζεται τα Προσωπικά Δεδομένα σας μπορεί να βρίσκονται
      σε άλλες χώρες εκτός από αυτήν στην οποία πραγματοποιήθηκε η αρχική συλλογή των Προσωπικών
      Δεδομένων σας. Η νομοθεσία στις εν λόγω χώρες μπορεί να μην παρέχει το ίδιο επίπεδο προστασίας
      δεδομένων σε σύγκριση με τη χώρα που παρείχε αρχικά τα Προσωπικά Δεδομένα σας. Εντούτοις, όταν
      μεταφέρουμε τα Προσωπικά Δεδομένα σας σε παραλήπτες σε άλλες χώρες, συμπεριλαμβανομένων των
      ΗΠΑ, της Ελβετίας κ.ο.κ., δεσμευόμαστε να προστατεύουμε τα Προσωπικά δεδομένα σας όπως
      περιγράφεται στην παρούσα Πολιτική Απορρήτου και σύμφωνα με την ισχύουσα νομοθεσία. Λαμβάνουμε
      μέτρα για τη συμμόρφωση με τις ισχύουσες νομικές απαιτήσεις για τη μεταφορά προσωπικών
      δεδομένων σε παραλήπτες σε χώρες εκτός του Ευρωπαϊκού Οικονομικού Χώρου ή την Ελβετία που δεν
      εξασφαλίζουν επαρκές επίπεδο προστασίας. Χρησιμοποιούμε διάφορα μέτρα για να διασφαλίζουμε ότι
      τα Προσωπικά σας Δεδομένα που μεταφέρονται σε αυτές τις χώρες απολαμβάνουν επαρκή προστασία
      σύμφωνα με τους κανόνες προστασίας δεδομένων. Αυτά περιλαμβάνουν την υπογραφή των Συμβατικών
      Ρητρών, την πιστοποίηση ότι ο παραλήπτης έχει υιοθετήσει τους Ευρωπαϊκούς δεσμευτικούς κανόνες
      ή τηρεί την Ασπίδα Προστασίας (Privacy Shield) μεταξύ ΕΕ-ΗΠΑ και Ελβετία - ΗΠΑ.
      <br />
      12. Για πόσο χρονικό διάστημα διατηρούμε τα Δεδομένα σας;
      <br />
      <br />
      Διατηρούμε τα Προσωπικά Δεδομένα σας όσο χρειάζεται για να εκπληρώσουμε τους σκοπούς που
      ορίζονται στην παρούσα Πολιτική Απορρήτου (εκτός αν απαιτείται από την ισχύουσα νομοθεσία
      μεγαλύτερη περίοδος διατήρησης). Γενικά αυτό σημαίνει ότι θα διατηρήσουμε τα Προσωπικά σας
      Δεδομένα για όσο διάστημα έχετε λογαριασμό στην Εταιρεία μας. Αναφορικά με τα Προσωπικά σας
      Δεδομένα που σχετίζονται με τις προσφερόμενες υπηρεσίες μας και τα συναφή προϊόντα, διατηρούμε
      αυτά τα δεδομένα για μεγαλύτερο διάστημα προκειμένου να συμμορφωθούμε με τις νομικές μας
      υποχρεώσεις (όπως τη φορολογική και εμπορική νομοθεσία και για λόγους παροχής εγγύησης όπου
      συντρέχει). Στο τέλος αυτής της περιόδου διατήρησης, τα δεδομένα σας θα διαγραφούν πλήρως ή
      ανώνυμα, για παράδειγμα με τη συγκέντρωση με άλλα δεδομένα, έτσι ώστε να μπορούν να
      χρησιμοποιηθούν με μη αναγνωρίσιμο τρόπο για στατιστική ανάλυση και επιχειρηματικό
      προγραμματισμό. Μερικά παραδείγματα περιόδων διατήρησης Δεδομένων πελατών:
      <br />
      ●Υπηρεσίες
      <br />
      Όταν κάνετε χρήση υπηρεσίας, θα διατηρήσουμε τα προσωπικά δεδομένα που μας δώσατε για πέντε
      χρόνια, ώστε να μπορέσουμε να συμμορφωθούμε με τις νομικές και συμβατικές υποχρεώσεις μας.
      <br />
      <br />
      ●Newsletter
      <br />
      Η δήλωση συγκατάθεσής σας για αποστολή ενημερωτικού δελτίου (newsletter) τηρείται για όσο
      χρόνο σας αποστέλλεται newsletter από την Εταιρεία και πάντως όχι περισσότερο από έξι μήνες
      από τη διακοπή αποστολής του.
      <br />
      <br />
      13. Είναι ασφαλή τα Δεδομένα σας;
      <br />
      Δεσμευόμαστε να διαφυλάσσουμε τα Προσωπικά σας Δεδομένα.
      <br />
      Αναγνωρίζοντας τη σημασία της ασφαλείας των Προσωπικών Δεδομένων σας, έχουμε λάβει όλα τα
      κατάλληλα οργανωτικά και τεχνικά μέτρα για την ασφάλεια και την προστασία των Δεδομένων σας
      από κάθε μορφής τυχαία ή αθέμιτη επεξεργασία. Χρησιμοποιούμε τις πιο σύγχρονες και προηγμένες
      μεθόδους, ώστε να εξασφαλίζεται η μέγιστη δυνατή ασφάλεια.
      <br />
      Ο ισότοπός μας χρησιμοποιεί τα πιο σύγχρονα και ασφαλή πρωτόκολλα με σκοπό την διασφάλιση των
      online εμπορικών συναλλαγών. Με αυτόν τον τρόπο κρυπτογραφούνται όλα τα Δεδομένα που παρέχετε,
      συμπεριλαμβανομένου του αριθμού της πιστωτικής σας κάρτας, του ονόματος και της διεύθυνσης
      σας, έτσι ώστε να μην μπορούν να αποκρυπτογραφηθούν ή να αλλαχθούν κατά τη μεταφορά τους στο
      Internet.
      <br />
      Επιπρόσθετα, τα στοιχεία που χρησιμοποιούνται για την αναγνώρισή σας ως χρήστη λογαριασμού
      είναι δύο: ο Κωδικός Εισόδου (Username) και ο Προσωπικός Μυστικός Κωδικός Ασφαλείας
      (Password). Κάθε φορά που καταχωρείτε τα στοιχεία σας, σας παρέχεται πρόσβαση στον προσωπικό
      σας λογαριασμό. Η συγκεκριμένη διαδικασία επιτυγχάνεται με ασφάλεια μέσω της κρυπτογράφησης
      κατά τη μεταφορά τους στο διαδίκτυο και τους εξυπηρετητές (servers) της Εταιρείας. Κατά τα
      ίδια πρότυπα, σας δίδεται η δυνατότητα να μεταβάλλετε τον Προσωπικό Μυστικό Κωδικό Ασφαλείας
      σας (Password) όσο συχνά εσείς επιθυμείτε. Μετά την καταχώρηση του επιθυμητού κωδικού, ο νέος
      κωδικός κωδικοποιείται και αποθηκεύεται στα συστήματα της Εταιρείας. Για τον λόγο αυτό, ο
      μόνος που γνωρίζει τον κωδικό σας είστε ο ίδιος και είστε αποκλειστικά υπεύθυνος για τη
      διατήρηση της μυστικότητας του κωδικού από τρίτα πρόσωπα.
      <br />
      <br />
      Τα εν λόγω μέτρα επανεξετάζονται και τροποποιούνται όταν κρίνεται απαραίτητο.
      <br />
      <br />
      14. Ποια είναι τα δικαιώματά σας;
      <br />
      Έχετε δικαίωμα πρόσβασης στα Προσωπικά σας Δεδομένα.
      <br />
      Αυτό σημαίνει ότι έχετε το δικαίωμα να ενημερωθείτε από εμάς εάν επεξεργαζόμαστε Δεδομένα σας.
      Αν επεξεργαζόμαστε Δεδομένα σας μπορείτε να ζητήσετε να ενημερωθείτε για τον σκοπό της
      επεξεργασίας, το είδος των Δεδομένων σας που τηρούμε, σε ποιους τα δίνουμε, πόσο διάστημα τα
      αποθηκεύουμε, αν γίνεται αυτοματοποιημένη λήψη αποφάσεων, αλλά και για τα λοιπά δικαιώματα
      σας, όπως διόρθωσης, διαγραφής δεδομένων, περιορισμού της επεξεργασίας και υποβολής
      καταγγελίας στην Αρχή Προστασίας Προσωπικών Δεδομένων.
      <br />
      Έχετε δικαίωμα διόρθωσης ανακριβών δεδομένων προσωπικού χαρακτήρα.
      <br />
      Αν διαπιστώσετε ότι υφίσταται λάθος στα Δεδομένα σας μπορείτε να μας υποβάλλετε αίτηση για να
      τα διορθώσουμε (π.χ. διόρθωση ονόματος ή ενημέρωση αλλαγής διεύθυνσης).
      <br />
      Έχετε δικαίωμα διαγραφής/δικαίωμα στη λήθη.
      <br />
      Μπορείτε να μας ζητήσετε να διαγράψουμε τα δεδομένα σας αν δεν είναι απαραίτητα πλέον για τους
      ως άνω αναφερόμενους σκοπούς επεξεργασίας ή επιθυμείτε να ανακαλέσετε τη ή σας στην περίπτωση
      που αυτή είναι η μόνη νόμιμη βάση.
      <br />
      Έχετε δικαίωμα φορητότητας των Δεδομένων σας.
      <br />
      Μπορείτε να μας ζητήσετε να λάβετε σε αναγνώσιμη μορφή τα Δεδομένα που έχετε παράσχει ή να μας
      ζητήσετε να τα διαβιβάσουμε σε άλλο υπεύθυνο επεξεργασίας.
      <br />
      Έχετε δικαίωμα περιορισμού της επεξεργασίας.
      <br />
      Μπορείτε να μας ζητήσετε να περιορίσουμε την επεξεργασία των Δεδομένων σας για όσο χρόνο
      εκκρεμεί η εξέταση των αντιρρήσεών σας ως προς την επεξεργασία.
      <br />
      Έχετε δικαίωμα εναντίωσης και ανάκλησης συγκατάθεσης στην επεξεργασία των Δεδομένων σας.
      <br />
      Μπορείτε να αντιταχθείτε στην επεξεργασία των Δεδομένων σας και εμείς θα σταματήσουμε την
      επεξεργασία των Δεδομένων σας, αν δεν υφίστανται άλλοι επιτακτικοί και νόμιμοι λόγοι που
      υπερισχύουν έναντι του δικαιώματός σας. Εάν έχετε δηλώσει τη συγκατάθεσή σας για τη συλλογή,
      επεξεργασία και χρήση των δεδομένων σας προσωπικού χαρακτήρα, μπορείτε να ανακαλέσετε τη
      συγκατάθεσή σας ανά πάσα στιγμή με μελλοντική ισχύ:
      <br />
      <br />
      ●Επιλέγοντας να μη λαμβάνετε Επικοινωνίες Marketing.
      <br />
      Μπορείτε να επιλέξετε να μη λαμβάνετε επικοινωνίες marketing αλλάζοντας τις εγγραφές email και
      sms πατώντας το link διαγραφή ή ακολουθώντας τις οδηγίες που περιλαμβάνονται στο μήνυμα.
      <br />
       Εναλλακτικά μπορείτε να επικοινωνήσετε μαζί μας χρησιμοποιώντας τα στοιχεία επικοινωνίας που
      σας δίνουμε στον σχετικό όρο παρακάτω.
      <br />
      <br />
      Σε περίπτωση που βασιζόμαστε στο νόμιμο συμφέρον μας: Σε περιπτώσεις όπου επεξεργαζόμαστε τα
      προσωπικά σας δεδομένα με βάση το νόμιμο συμφέρον μας, μπορείτε να μας ζητήσετε να
      σταματήσουμε για λόγους που σχετίζονται με την προσωπική σας κατάσταση. Πρέπει τότε να το
      πράξουμε αν δεν πιστεύουμε ότι έχουμε νόμιμο επιτακτικό λόγο να συνεχίσουμε να επεξεργαζόμαστε
      τα Προσωπικά σας Δεδομένα.
      <br />
      <br />
      15. Πώς μπορείτε να ασκήσετε τα δικαιώματα σας;
      <br />
      Για να ασκήσετε τα δικαιώματα σας μπορείτε να μας υποβάλλετε σχετικό αίτημα στην ηλεκτρονική
      διεύθυνσή www.vivianlab.com. site με τίτλο «Άσκηση Δικαιώματος» και εμείς θα το εξετάσουμε και
      θα σας απαντήσουμε το συντομότερο δυνατό. Κατ’ εξαίρεση:
      <br />
      ●αν επιθυμείτε τη διόρθωση των Δεδομένων σας στο λογαριασμό χρήστη σας, μπορείτε να συνδεθείτε
      σε αυτόν και να πραγματοποιήσετε οιανδήποτε διόρθωση/ μεταβολή χωρίς να απαιτείται η υποβολή
      Αιτήματος. <br />
      ●αν επιθυμείτε την ανάκληση της συγκατάθεσης σας για αποστολή ενημερωτικού δελτίου
      (newsletter) μπορείτε να την πραγματοποιείτε με την επιλογή του συνδέσμου «Για διαγραφή από τη
      «λίστα αποστολής newsletter» κάντε κλικ εδώ» που βρίσκεται στο κάτω μέρος κάθε newsletter.
      <br />
      ●αν επιθυμείτε να μην λαμβάνετε web push notifications από την Εταιρεία μπορείτε να
      απενεργοποιήσετε την επιλογή από τη ρύθμιση περιήγησης (browser) σας.
      <br />
      Έλεγχος ταυτοπροσωπίας
      <br />
      <br />
      Για να προστατεύσουμε την εμπιστευτικότητα των πληροφοριών σας, θα σας ζητήσουμε να
      επαληθεύσετε την ταυτότητά σας προτού προχωρήσετε σε οποιοδήποτε αίτημα υποβάλετε με βάση την
      παρούσα Πολιτική Απορρήτου. Εάν έχετε εξουσιοδοτήσει ένα τρίτο μέρος να υποβάλει αίτημα εκ
      μέρους σας, θα του ζητήσουμε να αποδείξει ότι έχει την άδειά σας να ενεργήσει για αυτό το
      σκοπό.
      <br />
      <br />
      16. Πότε απαντάμε στα Αιτήματα σας; Απαντάμε στα Αιτήματά σας δωρεάν χωρίς καθυστέρηση, και σε
      κάθε περίπτωση εντός <br />
      (1) ενός μηνός από τότε που θα λάβουμε το αίτημά σας. Αν, όμως, το Αίτημά σας είναι πολύπλοκο
      ή υπάρχει μεγάλος αριθμός Αιτημάτων σας, θα σας ενημερώσουμε εντός του μήνα αν χρειαστεί να
      λάβουμε παράταση άλλων <br />
      (2) δύο μηνών εντός των οποίων θα σας απαντήσουμε.
      <br />
      <br />
      17. Ποιο είναι το εφαρμοστέο δίκαιο κατά την επεξεργασία των Δεδομένων σας από εμάς;
      <br />
      Εφαρμοστέο Δίκαιο είναι το Ελληνικό Δίκαιο, όπως διαμορφώνεται σύμφωνα με το Γενικό Κανονισμό
      για την Προστασία των Προσωπικών Δεδομένων 2016/679/ΕΕ, και εν γένει το ισχύον εθνικό και
      ευρωπαϊκό νομοθετικό και κανονιστικό πλαίσιο για την προστασία προσωπικών δεδομένων.
      <br />
      Tυχόν διαφορά προκύψει από ή σχετικά με την προστασία των Προσωπικών Δεδομένων σας υπάγεται
      προς επίλυση σε διαμεσολάβηση σύμφωνα με τον Κανονισμό Διαμεσολάβησης του Ευρωπαϊκού
      Οργανισμού Διαμεσολάβησης και Διαιτησίας (ΕΟΔΙΔ). Σε περίπτωση που η διαφορά ή μέρος αυτής δεν
      επιλυθεί μέσω διαμεσολάβησης, η διαφορά ή το μη επιλυθέν τμήμα της επιλύεται αποκλειστικά,
      τελεσίδικα και αμετάκλητα από διαιτητικό δικαστήριο, που ορίζεται και διεξάγει τη διαιτησία
      σύμφωνα με τον Κανονισμό Διαιτησίας ΕΟΔΙΔ.
      <br />
      <br />
      Σε κάθε περίπτωση αμφισβήτησης των ανωτέρω αρμόδια δικαστήρια ορίζονται τα αρμόδια δικαστήρια
      της πόλης των Αθηνών.
      <br />
      <br />
      18. Πού μπορείτε να προσφύγετε αν παραβιάσουμε το ισχύον δίκαιο για την προστασία των
      Προσωπικών Δεδομένων σας; Έχετε δικαίωμα να υποβάλλετε καταγγελία στην Αρχή Προστασίας
      Προσωπικών Δεδομένων (ταχυδρομική δ/νση Κηφισίας 1-3, Τ.Κ. 115 23, Αθήνα, τηλ. 210. 6475600,
      δ/νση ηλεκτρονικού ταχυδρομείου (e-mail) contact@dpa.gr), αν θεωρείτε ότι η επεξεργασία των
      Προσωπικών Δεδομένων σας παραβιάζει το ισχύον εθνικό και κανονιστικό πλαίσιο δίκαιο για την
      προστασία των προσωπικών δεδομένων.
      <br />
      <br />
      19. Πώς θα ενημερωθείτε για τυχόν τροποποιήσεις της παρούσας Πολιτικής; Ενημερώνουμε την
      παρούσα Πολιτική Απορρήτου όποτε αυτό είναι αναγκαίο. Εάν υπάρχουν σημαντικές αλλαγές στην
      Πολιτική Απορρήτου ή στον τρόπο με τον οποίο χρησιμοποιούμε τα Προσωπικά Δεδομένα σας, θα
      δημοσιεύουμε στην ιστοσελίδα μας την επικαιροποίηση της παρούσας, προτού οι αλλαγές τεθούν σε
      ισχύ και θα σας ειδοποιούμε με κάθε πρόσφορο τρόπο. Σας ενθαρρύνουμε να διαβάζετε, ανά τακτά
      διαστήματα, την παρούσα Πολιτική για να γνωρίζετε πώς προστατεύονται τα Δεδομένα σας. Η
      παρούσα πολιτική απορρήτου τροποποιήθηκε για τελευταία φορά 16th/Νοέμβριος/2023. Παραμένουμε
      στη διάθεσή σας για πάσα περαιτέρω πληροφορία και προβληματισμό που πιθανόν να προκύπτουν από
      την παρούσα Δήλωση – Πολιτική Απορρήτου και παρακαλούμε όπως επικοινωνήσετε με την Εταιρεία
      μας στο εξής info@vivianlab.com.
      <br />
      <br />
    </div>
  );
}

export default PrivacyGreek;
